import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  background-color: ${grayscale[500]};

  .react-multi-carousel-track {
     padding: 0 0 8px 0;
  }

  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button {
        background-color: #FF7A00;
      }
    }
  }

  .react-multiple-carousel__arrow--right {
    svg{
      fill: #FF7A00;
    }
  }

  .react-multiple-carousel__arrow--left {
    svg{
      fill: #FF7A00;
    }
  }
`
