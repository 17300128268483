import styled from 'styled-components'

export const SectionWrapper = styled.section`
  .description {
    min-height: 100px;
  }
  h3 {
    min-height: 50px;
  }
`
