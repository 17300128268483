import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      investPrint1: imageSharp(fluid: {originalName: { regex: "/rendaVariavelPrint1/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      investPrint2: imageSharp(fluid: {originalName: { regex: "/rendaVariavelPrint2/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      investPrint3: imageSharp(fluid: {originalName: { regex: "/rendaVariavelPrint3/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      rendaVocePorDentro: imageSharp(fluid: {originalName: { regex: "/renda-variavel-voce-por-dentro/" }}) {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      rendaLimiteOperacional: imageSharp(fluid: {originalName: { regex: "/renda-variavel-limite-operacional/" }}) {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      rendaBlog1: imageSharp(fluid: {originalName: { regex: "/renda-variavel-blog1/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      rendaBlog2: imageSharp(fluid: {originalName: { regex: "/renda-variavel-blog2/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      rendaBlog3: imageSharp(fluid: {originalName: { regex: "/renda-variavel-blog3/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      rendaInterResearchIcon: imageSharp(fluid: {originalName: { regex: "/Inter-research/" }}) {
        fluid(maxWidth: 40, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      rendaInterInvestShopIcon: imageSharp(fluid: {originalName: { regex: "/renda-InvestShop/" }}) {
        fluid(maxWidth: 40, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      rendaSavings: imageSharp(fluid: {originalName: { regex: "/renda-savings/" }}) {
        fluid(maxWidth: 30, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      rendaSallary: imageSharp(fluid: {originalName: { regex: "/renda-sallary/" }}) {
        fluid(maxWidth: 30, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      rendaMarket: imageSharp(fluid: {originalName: { regex: "/renda-market/" }}) {
        fluid(maxWidth: 30, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      profitImg: imageSharp(fluid: {originalName: { regex: "/profitImg/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      homeBroker: imageSharp(fluid: {originalName: { regex: "/homeBroker/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      atomImg: imageSharp(fluid: {originalName: { regex: "/atomImg/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      irTradeImg: imageSharp(fluid: {originalName: { regex: "/irTradeImg/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bussolaImg: imageSharp(fluid: {originalName: { regex: "/bussolaImg/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
