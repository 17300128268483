import React from 'react'
import { SectionWrapper } from './style'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

const OMelhorDoBanco = ({ sendDatalayerEvent }: DataLayerProps) => {
  return (
    <SectionWrapper id='melhor-do-banco'>
      <div className='container py-5'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white mb-md-0'>
              O melhor do banco<span className='d-block'>e da corretora</span>
            </h2>
          </div>
          <div className='col-12 col-md-6'>
            <p className='fs-16 lh-20 fs-md-18 lh-md-22 text-white mb-md-0'>
              No Inter você conta com todas as facilidades de uma conta digital completa e
              ao mesmo tempo com uma
              <a
                href='https://inter.co/pra-voce/investimentos/'
                target='_blank' rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    section_name: 'O melhor do banco e da corretora',
                    element_action: 'click button',
                    element_name: 'Plataforma de investimentos',
                    })
                }}
              >
                <span className='text-orange--extra fw-600'> plataforma de investimentos</span>
              </a> com soluções pra você investir com inteligência e alcançar melhores resultados.
            </p>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default OMelhorDoBanco
