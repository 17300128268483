import React from 'react'
import Img from 'gatsby-image'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import Icon from 'src/components/UI/MarkdownIcon'

import usePageQuery from './../../pageQuery'
import BlogArticlesCashbacksJSON from './BlogArticlesCashbacks.json'
import { SectionWrapper } from './style'

type blogItem ={
  image: string;
  primaryTag: string;
  secundaryTag: string;
  title: string;
  data: string;
  description: string;
  link: string;
  alt: string;
}

const Blog = ({ sendDatalayerEvent }: DataLayerProps) => {
  const data = usePageQuery()
  const urlNossoBlog = 'https://blog.inter.co'

  return (
    <SectionWrapper>
      <div className='container py-5'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-8 text-none text-md-center'>
            <h2 className='fs-20 fs-md-24 fs-lg-40 lh-26 lh-md-30 lh-lg-50 text-grayscale--500'>
              Os melhores conteúdos sobre investimentos
            </h2>
            <p className='fs-18 lh-20 text-grayscale--500'>
              Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.
            </p>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 2 }}
              lg={{ items: 3 }}
              xl={{ items: 3 }}
            >
              {BlogArticlesCashbacksJSON.map((item: blogItem, index: number) => (
                <div key={index} className='col-12 p-card mt-md-3'>
                  <article className='col-12 px-0'>
                    <Img fluid={data[item.image].fluid} alt={item.alt} />
                    <div className='col-12 pt-3 rounded-5 px-4 mt-n4 bg-white'>
                      <p className='fs-12 lh-15 fw-600 text-white bg-orange--extra rounded-1 mb-1 px-2 mr-2 primary-tag'>{item.primaryTag}</p>
                      <p className='fs-12 lh-15 fw-600 text-orange--extra bg-gray-400 rounded-1 mb-1 px-2 secundary-tag'>{item.secundaryTag}</p>
                      <p className='fs-20 lh-25 fw-600 mt-3 mb-2 mt-md-2 mt-xl-2 title-post text-grayscale--500'>{item.title}</p>
                      <span className='fs-12 lh-15 fw-400 d-block text-grayscale--300'>{item.data}</span>
                      <p className='fs-14 lh-19 lh-md-19 mb-2 fw-400 d-none d-lg-block mt-3 text-grayscale--400'>{item.description}</p>
                      <a
                        data-blog={item.title}
                        href={item.link}
                        title={`Leia mais em ${item.link}`}
                        className='fs-14 lh-17 fw-400 text-orange--extra text-right d-none d-lg-block mt-xl-3'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_10',
                            section_name: 'Quer saber das novidades do mundo dos investimentos?',
                            element_action: 'click button',
                            element_name: item.title,
                            redirect_url: item.link,
                          })
                        }}
                      >
                        Leia mais
                        <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                      </a>
                    </div>
                  </article>
                </div>
               ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default Blog
