import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from './../../pageQuery'
import { SectionWrapper } from './style'

const LimiteOperacional = () => {
  const data = usePageQuery()

  return (
    <SectionWrapper>
      <div className='container py-5'>
        <div className='row justify-content-between'>
          <div className='col-12 col-lg-6 d-flex flex-column justify-content-center'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 text-grayscale--500 mb-3'>Limite Operacional</h2>
            <p className='fs-18 lh-22 text-grayscale--500'>{'O limite operacional é o valor que você poderá utilizar para realizar operações na bolsa de valores. Para Bovespa, ele é calculado diariamente levando em consideração seu saldo em conta corrente e sua posição de investimentos em renda fixa e renda variável.  Para BM&F, é necessário realizar a alocação do saldo na aba Investimentos > Renda Variável'}</p>
            <p className='fs-18 lh-22 text-grayscale--500'>A liquidação das operações realizadas será feita em conta corrente. Caso não haja saldo suficiente, sua conta ficará negativa, acarretando no risco de liquidação de seus ativos e na incidência dos seguintes encargos: 0,38% de IOF ao ficar negativo + 0,0082% de IOF ao dia + 7,7% ao mês.</p>
          </div>
          <div className='col-12 col-lg-5 d-none d-lg-block'>
            <Img fluid={data.rendaLimiteOperacional.fluid} alt='Dois executivos tomando café' />
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default LimiteOperacional
