import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import Img from 'gatsby-image'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import CarouselData from './dataCarousel.json'
import OpenModal from 'src/components/OpenModal'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import usePageQuery from './../../pageQuery'

import { SectionWrapper } from './style'

type carouselData = {
  title: string;
  description: string;
  image: string;
}

const ComeceAInvestirJa = ({ sendDatalayerEvent }: DataLayerProps) => {
  const data = usePageQuery()
    return (
      <SectionWrapper>
        <div className='container pt-4 pb-5'>
          <div className='row'>
            <div className='col-12'>
              {useWidth() >= 768
              ? (
                <div className='text-center'>
                  <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500'>Comece a investir já</h2>
                  <p className='fs-18 lh-22 text-grayscale--500 mb-5'>
                    Saiba como começar a investir em renda variável
                    <span className='d-block'>de forma simples, pelo nosso app.</span>
                  </p>
                </div>
              )
              : <h2 className='fs-16'>Como investir em renda variável?</h2>
            }
            </div>
          </div>
          {useWidth() >= 768
          ? (
            <div className='row'>
              <div className='col-4 d-flex justify-content-start align-items-center flex-column text-center'>
                <h3 className='fs-20 lh-26 text-grayscale--500 title'>1 - Abra a <span className='d-block'>sua conta</span></h3>
                <p className='fs-18 lh-22 text-grayscale--500 text-center description px-lg-5'>Baixe o App e
                  <OpenModal to='open-your-account-form'>
                    <span
                      className='text-orange--extra fw-600 cursor-pointer'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_04',
                          section_name: 'Comece a investir já',
                          element_action: 'click button',
                          element_name: 'Abra a sua conta gratuita',
                        })
                      }}
                    > abra a sua conta gratuita
                    </span>
                  </OpenModal>
                </p>
                <Img fluid={data.investPrint1.fluid} alt='Print da tela de login do app Inter' />
              </div>
              <div className='col-4 d-flex justify-content-start align-items-center flex-column text-center'>
                <h3 className='fs-20 lh-26 text-grayscale--500 title'>2 - Descubra <span className='d-block'>o seu perfil</span></h3>
                <p className='fs-18 lh-22 text-grayscale--500 text-center description'>Faça o teste e conheça <span className='d-block'>seu perfil de investidor.</span></p>
                <Img fluid={data.investPrint2.fluid} alt='Print da tela de perfil de investidor do app Inter' />
              </div>
              <div className='col-4 d-flex justify-content-start align-items-center flex-column text-center'>
                <h3 className='fs-20 lh-26 text-grayscale--500 title'>3 - Comece <span className='d-block'>a investir</span></h3>
                <p className='fs-18 lh-22 text-grayscale--500 text-center description px-lg-3'>Vá em Renda Variável, e conheça os produtos de acordo com seu perfil.</p>
                <Img fluid={data.investPrint3.fluid} alt='Print da tela de renda variável do app Inter' />
              </div>
            </div>
            )
            : (
              <div>
                <DefaultCarousel
                  sm={{
                      items: 1,
                  }}
                  md={{
                      items: 1,
                  }}
                  lg={{
                      items: 2,
                  }}
                  xl={{
                      items: 2,
                  }}
                >
                  { CarouselData.map((item: carouselData, index: number) => (
                    <div key={index}>
                      <div className='d-flex justify-content-start align-items-center flex-column'>
                        <div className='description mb-5'>
                          <h2 className='fs-24 lh-30 text-grayscale--500 text-start' dangerouslySetInnerHTML={{ __html: item.title }} />
                          {index === 0
                          ? (
                            <OpenModal to='open-your-account-form'>
                              <p
                                className='fs-18 text-grayscale--500'
                                dangerouslySetInnerHTML={{ __html: item.description }}
                                onClick={() => {
                                  sendDatalayerEvent({
                                    section: 'dobra_04',
                                    section_name: 'Como investir em renda variável?',
                                    element_action: 'click button',
                                    element_name: 'Clicando aqui',
                                  })
                                }}
                              />
                            </OpenModal>
                          )
                            : <p className='fs-18 text-grayscale--500' dangerouslySetInnerHTML={{ __html: item.description }} />
                          }
                        </div>
                        <Img fluid={data[item.image].fluid} alt='Print da tela de login do app Inter' />
                      </div>
                    </div>
                  )) }

                </DefaultCarousel>
              </div>
            )
          }
        </div>
      </SectionWrapper>
  )
}

export default ComeceAInvestirJa
