import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

// Images
import ContentHeader from './../../assets/renda-variavel-content-header.png'
import ContentHeaderTablet from './../../assets/renda-variavel-tablet.jpg'
import ContentHeaderLG from './../../assets/renda-variavel-lg.jpg'
import ContentHeaderMobile from './../../assets/renda-variavel-content-header-mb.jpg'

export const SectionWrapper = styled.section`
  align-items: center;
  background-image: url(${ContentHeaderMobile});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 510px;
  position: relative;

  @media ${device.mobile} {
    min-height: 560px;
    background-image: url(${ContentHeaderTablet});
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.tablet} {
    background-image: url(${ContentHeaderTablet});
  }

  @media ${device.desktopLG} {
    min-height: 100vh;
    background-image: url(${ContentHeaderLG});
  }

  @media ${device.desktopXL} {
    background-image: url(${ContentHeader});
  }

  .scroll-link {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;  
  }

  .btn {
    max-width: 100%;
    width: 100%;
  }
`
