import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange, grayscale } from 'src/styles/colors'

// Images
import Liberdade from './../../assets/renda-variavel-liberdade.png'
import LiberdadeMD from './../../assets/renda-variavel-liberdade-md.png'
import LiberdadeLG from './../../assets/renda-variavel-liberdade-lg.png'
import LiberdadeXL from './../../assets/renda-variavel-liberdade-xl.png'

export const SectionWrapper = styled.section`
  min-height: 800px;
  background-color: ${grayscale[500]};
  display: flex;
  align-items: flex-end;
  background-image: url(${Liberdade});
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;

  @media ${device.mobile} {
    min-height: 980px;
    background-position: 0 -80px;
  }

  @media ${device.tablet} {
    min-height: 500px;
    background-position: -40px;
    background-image: url(${LiberdadeMD});
    align-items: center;
  }

  @media ${device.desktopLG} {
    background-position: 0;
    background-image: url(${LiberdadeLG});
  }

  @media ${device.desktopXL} {
    min-height: 500px;
    background-position: -100px;
    background-image: url(${LiberdadeXL});
  }

  @media ${device.desktopXXL} {
    min-height: 500px;
    background-position: -50px;
    background-image: url(${LiberdadeXL});
  }

  .icon {
    margin-left: -55px;
    margin-top: 2px;
  }

  .icon-background {
    background-color: ${orange.extra};
    opacity: 0.2;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
`
