import React from 'react'
import Faq from 'src/components/Faq'
import { SectionWrapper } from './style'

type propsType = {
  data: {question: string; answer: string}[];
}

const FAQ = (props: propsType) => {
    return (
      <SectionWrapper>
        <div className='container py-5'>
          <div className='row'>
            <div className='mb-4'>
              <h2 className='fs-32 lh-40 fs-md-40 lh-md-50 text-grayscale--500'>Ficou com alguma dúvida?</h2>
              <p className='text-grayscale--500 fs-16'>Veja se podemos te ajudar com essas perguntas frequentes:</p>
            </div>
            <Faq answerData={props.data} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} className='px-0' />
          </div>
        </div>
      </SectionWrapper>
  )
}

export default FAQ
