import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { black } from 'src/styles/colors'

export const SectionWrapper = styled.section`
    min-height: 500px;
    display: flex;
    align-items: center;

`
