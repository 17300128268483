import styled from 'styled-components'
import { orange, grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  min-height: 300px;
  background-color: ${grayscale[500]};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${grayscale[500]};
  border-top: 1px solid ${grayscale[500]};

  @media ${device.desktopXL} {
    min-height: 500px;
  }

  .icon {
    margin-left: -52px;
    margin-top: 2px;
  }

  .icon-background {
    background-color: ${orange.extra};
    opacity: 0.2;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: -30px;
  }
`
