import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
    .carousel-card {
      min-height: 330px;
    }

    @media ${device.desktopXL} {
      .button-investir {
        width: 250px;
      }
    }

`
