import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import BGImage from './../../assets/renda-variavel-o-que-e-bg.png'

export const SectionWrapper = styled.section`
    min-height: 300px;
    background-color: ${grayscale[100]};
    background-image: url(${BGImage});
    display: flex;
    align-items: center;
`
