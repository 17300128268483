import React from 'react'
import Img from 'gatsby-image'
import { SectionWrapper } from './style'
import pageQuery from './../../pageQuery'
import FerramentasJSON from '../../assets/data/ferramentas.json'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

interface IParaVoceInvestirMelhorProps {
  handleClick: () => void;
  sendDatalayerEvent: Function;
}

type FerramentasCarouselProps = {
  image: string;
  title: string;
  decription: string;
}

const ParaVoceInvestirMelhor = ({ handleClick, sendDatalayerEvent }: IParaVoceInvestirMelhorProps) => {
  const data = pageQuery()

  return (
    <SectionWrapper id='tenha-as-melhores-ferramentas-com-o-investpro'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 col-md-4 col-lg-5 pr-0 pl-md-0 pr-md-3 pl-lg-3 pr-lg-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-white fw-600 mb-3'>
              As melhores ferramentas para traders
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-19 lh-xl-22 text-grayscale--100 mb-0'>
              Opere suas ações com ferramentas automatizadas de forma transparente, ágil e confiável.
            </p>
            <button
              className='btn--lg mt-4 btn btn-orange--extra text-white rounded-2 fs-14 fw-700 text-none mw-100'
              onClick={() => {
                handleClick()
                sendDatalayerEvent({
                  section: 'dobra_05',
                  section_name: 'As melhores ferramentas para traders',
                  element_action: 'click button',
                  element_name: 'Quero ser trader',
                })
              }}
            >
              Quero ser trader
            </button>
          </div>
          <div className='col-12 col-md-8 col-lg-7 col-xl-6 offset-xl-1 offset-xxl-2 mt-2 mt-md-0 px-0'>
            <DefaultCarousel
              sm={{ items: 1, slidesToSlide: 1 }}
              md={{ items: 1, slidesToSlide: 1 }}
              lg={{ items: 1, slidesToSlide: 1 }}
              xl={{ items: 1, slidesToSlide: 1 }}
            >
              {
              FerramentasJSON.map((item: FerramentasCarouselProps, index: number) => (
                <div key={index} className='carousel-card rounded-3 py-3 pl-2'>
                  <div className='row align-items-center pl-lg-4 pl-xl-0'>
                    <div className='col-12 col-md-6 pb-4 d-flex justify-content-center'>
                      <Img fluid={data[item.image].fluid} />
                    </div>
                    <div className='col-12 col-md-6 px-0 pr-md-3'>
                      <h3
                        className='fs-20 lh-25 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 text-white font-sora fw-600'
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                      <p
                        className='fs-14 fs-lg-16 lh-17 lh-lg-19 text-grayscale--100'
                        dangerouslySetInnerHTML={{ __html: item.decription }}
                      />
                    </div>
                  </div>
                </div>
              ))
            }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default ParaVoceInvestirMelhor
