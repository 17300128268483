import styled from 'styled-components'

export const SectionWrapper = styled.section`
  min-height: 300px;
  display: flex;
  align-items: center;

  section {
    padding: 0;
  }

`
