import React, { useRef, useEffect } from 'react'
import { animated, useTrail } from 'react-spring'
import useScroll from 'src/hooks/window/useScroll'
import isVisible from 'src/utils/isVisible'
import { SectionWrapper } from './style'
import Link from 'components/GatsbyLinkWrapper'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import * as URLs from 'config/api/Urls'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const OQueE = ({ sendDatalayerEvent }: DataLayerProps) => {
  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
    }
  }, [ scroll ])

    return (
      <SectionWrapper ref={sectionRef}>
        <div className='container py-5'>
          <div className='row justify-content-between'>
            <div className='col-12 col-md-6 col-lg-5'>
              <animated.h2 style={animatedSection[0]} className='text-grayscale--500 fs-24 lh-30 fs-md-40 lh-md-50'>
                O que é <span className='d-inline d-md-block'>renda variável?</span>
              </animated.h2>
              <animated.p style={animatedSection[1]} className='text-grayscale--500 lh-22'>
                É um tipo de investimento no qual não é possível definir exatamente qual será seu rendimento em determinado período.
                São aplicações com maior grau de risco e ao mesmo tempo com possibilidades de alta rentabilidade em um curto espaço de tempo.
              </animated.p>
            </div>
            <div className='col-12 col-md-6 col-lg-5 mt-3 mt-md-0'>
              <animated.h2 style={animatedSection[0]} className='text-grayscale--500 fs-24 lh-30 fs-md-40 lh-md-50'>O que é <span className='d-inline d-md-block'>mercado futuro?</span></animated.h2>
              <animated.p style={animatedSection[1]} className='text-grayscale--500 mb-0 lh-22'>
                O
                <Link
                  to='/pra-voce/investimentos/mercado-futuro/'
                  className='fw-600 text-orange--extra d-none d-sm-inline'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_09',
                      section_name: 'O que é mercado futuro?',
                      element_action: 'click button',
                      element_name: ' Mercado Futuro',
                      redirect_url: URLs.SITE_BASE_URL + '/pra-voce/investimentos/mercado-futuro/',
                    })
                  }}
                > Mercado Futuro
                </Link>
                <span className='d-inline d-sm-none'>Mercado Futuro</span> é o segmento da bolsa de valores no qual ocorrem as negociações e vendas de commodities, moedas, taxas de juros e outros títulos. As negociações já tem um preço definido, porém possui data de vencimento no futuro. O lucro do investidor vem a partir da valorização do produto.
              </animated.p>
            </div>
          </div>
        </div>
      </SectionWrapper>
  )
}

export default OQueE
