import React, { useRef, useEffect } from 'react'
import { animated, useTrail } from 'react-spring'
import Img from 'gatsby-image'
import useScroll from 'src/hooks/window/useScroll'
import isVisible from 'src/utils/isVisible'
import usePageQuery from './../../pageQuery'
import { SectionWrapper } from './style'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const VocePorDentro = ({ sendDatalayerEvent }: DataLayerProps) => {
  const data = usePageQuery()
  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)
  const url = 'https://interinvest.inter.co/'

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
    }
  }, [ scroll ])

    return (
      <SectionWrapper ref={sectionRef}>
        <div className='container py-5'>
          <div className='row justify-content-between'>
            <div className='col-12 col-md-6 col-xl-5 order-2 order-md-1 d-flex flex-column justify-content-center'>
              <div className='d-flex align-items-center mt-3 mt-md-0'>
                <div className='icon-background' />
                <Img fluid={data.rendaInterResearchIcon.fluid} />
                <h2 className='fs-14 fw-600 text-white mb-0 ml-4'>INTER RESEARCH</h2>
              </div>
              <animated.h2 style={animatedSection[0]} className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white mt-5 mb-4'>
                Você por dentro <span className='d-block'>do mercado</span>
              </animated.h2>
              <animated.p style={animatedSection[1]} className='fs-16 fs-md-18 lh-20 lh-md-22 text-white'>
                Para você que busca se informar ao máximo para tomar as melhores decisões de investimento, nosso time de
                Estratégias e Pesquisas Econômicas te da todo o apoio necessário. Acesse o
                <a
                  href={url}
                  title='Acessar o site da Inter Research'
                  target='_blank' rel='noreferrer'
                  className='text-orange--extra fw-600'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_06',
                      section_name: 'Você por dentro do mercado',
                      element_action: 'click button',
                      element_name: 'Inter Research.',
                      redirect_url: url,
                    })
                  }}
                > Inter Research.
                </a>
              </animated.p>
            </div>
            <div className='col-12 col-md-6 col-lg-5 order-1 order-md-2'>
              <Img fluid={data.rendaVocePorDentro.fluid} alt='Duas pessoas discutindo um projeto' />
            </div>
          </div>
        </div>
      </SectionWrapper>
  )
}

export default VocePorDentro
