import React, { useRef, useEffect } from 'react'
import { animated, useTrail } from 'react-spring'
import useScroll from 'src/hooks/window/useScroll'
import isVisible from 'src/utils/isVisible'
import Icon from 'src/components/UI/MarkdownIcon'
import { SectionWrapper } from './style'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const LiberdadeParaEscolher = ({ sendDatalayerEvent }: DataLayerProps) => {
  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))

  const urlPortabilidade = 'https://inter.co/portabilidade-de-investimentos/'
  const urlFile = 'https://marketing.bancointer.com.br/arquivos/modelo-stvm-preenchida.dot'

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
    }
  }, [ scroll ])

    return (
      <SectionWrapper ref={sectionRef}>
        <div className='container pb-5 pt-md-5'>
          <div className='row justify-content-end'>
            <div className='col-12 col-md-6'>
              <div className='d-flex align-items-center mt-3 mt-md-0'>
                <div className='icon-background mr-4' />
                <Icon color='orange--extra' width='24' height='24' icon='products/cambio/cambio' directory='v2' className='icon' />
                <h2 className='fs-14 fw-600 text-white mb-0 ml-4 text-uppercase'>Portabilidade de Investimentos</h2>
              </div>
              <animated.h2 style={animatedSection[0]} className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white mt-5 mb-3'>
                Liberdade para escolher <span className='d-block'>o melhor pra você</span>
              </animated.h2>
              <animated.p style={animatedSection[1]} className='text-white fs-16 lh-20 fs-sm-18 lh-sm-22'>
                Faça sua transferência de custódia e escolha a corretora que mais combina com seus investimentos. Descubra como realizar a
                <a
                  className='fw-600 text-orange--500'
                  href={urlPortabilidade}
                  target='_blank' rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_07',
                      section_name: 'Liberdade para escolher o melhor pra você',
                      element_action: 'click button',
                      element_name: 'Portabilidade de Investimentos',
                      redirect_url: urlPortabilidade,
                    })
                  }}
                > Portabilidade de Investimentos
                </a>.
              </animated.p>
              <animated.p style={animatedSection[1]} className='text-white fs-16 lh-20 fs-sm-18 lh-sm-22'>
                Se você já tem código de investidor cadastrado no Inter, baixe o
                <a
                  className='fw-600 text-orange--500'
                  href={urlFile}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_07',
                      section_name: 'Liberdade para escolher o melhor pra você',
                      element_action: 'click button',
                      element_name: 'Termo de transferência de custódia',
                      redirect_url: urlFile,
                    })
                  }}
                > termo de transferência de custódia
                </a>, preencha o formulário e encaminhe à instituição na qual você atualmente mantém suas ações.
              </animated.p>
            </div>
          </div>
        </div>
      </SectionWrapper>
  )
}

export default LiberdadeParaEscolher
